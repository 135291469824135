
import Vue from "vue";
import moment from "moment/moment";
import ShareDropdown from "@/components/ShareDropdown.vue";
import MentoringProgramCertificateData from "@/components/Certificate/MentoringProgramCertificateData.vue";
import { mapGetters } from "vuex";
//@ts-ignore
import html2pdf from "html2pdf.js";

export default Vue.extend({
  name: "Program",
  components: { MentoringProgramCertificateData, ShareDropdown },
  props: {
    program: {
      type: Object
    },
    profile: Object
  },
  data() {
    return {
      currentTime: {},
      sessionDate: "",
      selectedProgram: null,
      certificate: null as any
    };
  },
  computed: {
    ...mapGetters({
      /*certificate: "mentorcast/getCertificate"*/
      settings: "certificates/getSettings"
    }),
    getBaseUrl(): any {
      return location.host;
    },
    momentDate(): any {
      return moment();
    }
  },
  methods: {
    profileLink(data: any) {
      if (data.type == "mentee") {
        return `/mentorees/${data.uuid}`;
      }
      if (data.type == "mentor") {
        return `/mentors/${data.uuid}`;
      }
    },
    getOtherUser() {
      return this.profile.type === "mentor" ? "mentee" : "mentor";
    },
    getUser() {
      return this.profile.type === "mentor" ? "mentor" : "mentee";
    },
    /*printThis() {
      this.$store
          .dispatch("mentorcast/GET_CERTIFICATE", {
            id: this.program.booking.mentorcast_seat_id
          })
          .then(data => {
            console.log('this.certificate.certificate_download_path', data.certificate_download_path)
            window.open(data.certificate_download_path, "_blank");
          });

    },*/
    async getCertificate(id: any) {
      await this.$store
        .dispatch("program/GET_CERTIFICATE", {
          id: id
        })
        .then(data => {
          console.log("GET_CERTIFICATES>>>>", data);
          this.certificate = data;
        });
    },

    async printThis(program: any) {
      this.selectedProgram = program;
      await this.getCertificate(program.id);

      const seletor = "certificate";
      const element = document.getElementById(seletor);

      const options = {
        margin: 0,
        enableLinks: true,
        filename: "Certificate",
        image: {
          type: "jpeg",
          quality: 1
        },
        html2canvas: {
          scale: 2,
          useCORS: true,
          dpi: 96,
          letterRendering: true,
          y: 0,
          scrollY: 0
        },
        jsPDF: {
          unit: "in",
          format: "a4",
          orientation: "portrait"
        }
      };

      html2pdf()
        .set(options)
        .from(element)
        .toPdf()
        .save();
      this.certificate = null;
      this.selectedProgram = null;

      /*this.$store
          .dispatch("session/GET_CERTIFICATE", {
            id: this.session.id
          })
          .then(data => {
            console.log('this.certificate.certificate_download_path', data.certificate_download_path)
            window.open(data.certificate_download_path, "_blank");
          });*/
    }
  }
});
